import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import moment from 'moment';
import Table from '../Table/Table';
import { loadClientEvent, saveMaintenance, setMaintenanceEvent, listClientAddresses } from '../Api/Api';

export default function EVModal({show, handleClose, client_ID, userName}){

    const $ = require('jquery');
    
    const [ docDaysBefore ] = useState(365);
    const [ clientIDparent, setClientIDparent ] = useState();
    const [ events, setEventsData ] = useState([]);

    const filteredCompliances = events.filter(row => row.event_status !== "RESOLVIDO");

    const columns = [
        {
            title: 'Evento',
            data: 'event_text',
            className: 'text-center align-middle',
            createdCell: function (cell, cellData, row) {
                //case 'Inspecoes e laudos'
                if (row.system_type_id == '6') {
                    $(cell).addClass('criticals');
                }
            }
        },
        {
            title: 'Data',
            data: 'event_date',
            className: 'text-center align-middle',
        },
        {
            title: 'Sistema de proteção',
            data: 'system_type_name',
            className: 'text-center align-middle'
        },
        {
            title: 'Dias restantes',
            data: 'valid_days',
            className: 'text-center align-middle',
            render: function (data, type, row) {
                let badgeClass = '';
                let badgeContent = '';

                if (data <= 15) {
                  badgeClass = 'badge bg-danger';
                } else if (data > 15 && data <= 30) {
                  badgeClass = 'badge bg-warning';
                } else if (data > 30) {
                  badgeClass = 'badge bg-info';
                }
            
                badgeContent = Math.sign(data) === -1 ? 'Vencido' : data + ' dia(s)';
            
                return `<span class="${badgeClass}">${badgeContent}</span>`;
            },
        },
        {
            title: 'Status',
            data: 'event_status',
            className: 'text-center align-middle',
            render: function (data, type, row) {
                let statusColor = "badge bg-secondary";
                let statusName = "N/A";

                if (row.event_status != null && row.event_status == "RESOLVIDO"){
                    statusColor = "badge bg-success";
                    statusName = "Resolvido";
                    return null; //oculta os resolvidos
                } else if (row.event_status != null && row.event_status == "EM TRATATIVA"){
                    statusColor = " badge bg-warning text-dark";
                    statusName = "Em tratativa";
                }

                return `<span class="${statusColor}">${statusName}</span>`;
            }
        },
        {
            title: 'Ações',
            data: null,
            className: 'text-center align-middle',
            visible: false
        }
    ];

    const actionButtons = (row) => {
        const generateNCButton = (
            <button
              className="btn btn-sm btn-outline-danger btnList"
              onClick={() => generateNC(row.event_id, row.event_text, row.system_type_id)}
            >
              Gerar NC
            </button>
          );
        
          return (
            <>
                {row.event_status === null && row.valid_days <= 30 ? generateNCButton : <span>-</span>}
            </>
        );
    }

    const hide = () => {
        handleClose();
    }

    const loadClientEvt = async (daysBefore) => {
        const res = await loadClientEvent(client_ID, daysBefore);
        if(res) {
            setEventsData(res.data);
            getFirtsCliIdPassByParentId(client_ID);
        }
    }

    // Só vai funcionar se o primeiro endereço cadastrado for aquele que esta sendo alterado os sistemas (unidades antigas podem dar problema)
    const getFirtsCliIdPassByParentId = async (client_ID) => {
        const res = await listClientAddresses(client_ID);
        if(res.data.length > 0) {
            setClientIDparent(res.data[0].client_id);
        }
    }

    const generateNC = async (event_id, event_text) => {
        let systemMaintenanceText = "O evento " + event_text + " está pendente de tratativa em relação à sua data";
        let maintenanceTypeId = "282";

        Swal.fire({
            backdrop: true,
            didOpen: async () => {
                Swal.showLoading();
                let postData = {
                    system_maintenance_text: systemMaintenanceText,
                    system_maintenance_action: "Renovação de evento",
                    system_maintenance_created: moment().format("YYYY-MM-DD HH:mm"),
                    system_type_id: "15",
                    maintenance_type_id: maintenanceTypeId,
                    user_id: sessionStorage.getItem('userId'),
                    client_id: clientIDparent
                }

                await saveMaintenance(postData)
                .then((response) => {

                    let res = response.data;

                    if(res.id) {
                        let postDataNC_event = {
                            event_id: event_id,
                            system_maintenance_id: res.id
                        }
                        
                        setMaintenanceEvent(postDataNC_event)
                        .then((response) => {

                            let resp = response.data;

                            if(resp.data == "success") {
                                Swal.fire({
                                    title: 'NC criada com sucesso!',
                                    icon: 'success',
                                    allowOutsideClick: false,
                                    showCancelButton: false,
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'Ok'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        setEventsData([]);
                                        loadClientEvt(docDaysBefore);
                                        hide();
                                    }
                                })
                            }
                        }).catch((error) => {
                            Swal.fire("OPS!", "O servidor encontrou um erro ao processar sua requisição", "error");
                            hide();
                        });
                    }
                }).catch((error) => {
                    Swal.fire("OPS!", "O servidor encontrou um erro ao processar sua requisição", "error");
                    hide();
                });
            },
        })
    }

    useEffect(()=>{
        loadClientEvt(docDaysBefore);
    }, [client_ID]);

    return (
        <>
            <Modal size="lg" show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {userName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12">
                        <div className="card recent-sales">
                            <div className="card-header">
                                <b>Eventos a vencer</b> | próximos {docDaysBefore} dias
                            </div>
                            <div className="card-body">
                                <Table data={filteredCompliances} columns={columns} btnColumn={5} actBtns={actionButtons}/>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}