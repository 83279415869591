import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import Table from '../Table/Table';
import Swal from 'sweetalert2';
import moment from 'moment';
import { setMaintenanceWarning } from '../Api/Api';
import HistoryModal from './HistoryModal';

export default function NCModal({ show, handleClose, systemMaintenance, warningList, setShow }){

    const [ hShow, setHShow ] = useState(false);
    const { register, handleSubmit, reset, formState: { errors }, getValues, setValue } = useForm();

    const columns = [
        {
            title: 'ID',
            data: 'system_maintenance_id',
            className: 'text-center',
        },
        {
            title: 'Descrição',
            data: 'maintenance_warning_text',
            width: '50%',
        },
        {
            title: 'Operador',
            data: 'user_name',
        },
        {
            title: 'Encarregado',
            data: 'maintenance_warning_contact',
            className: 'text-center',
        },
        {
            title: 'Data',
            data: 'maintenance_warning_date',
            className: 'text-center align-middle',
          },
        {
            title: '',
            data: null,
            visible: false
        }
    ];

    const actionButtons = (row) => {
        return (
            <>
            </>
        )
    }

    const save = async (data) => {
        data.systemMaintenanceId = systemMaintenance.system_maintenance_id;
        data.userId = sessionStorage.getItem("userId");
        Swal.fire({
            backdrop: true,
            allowOutsideClick: false,
            icon: 'success',
            text: "Tratativa incluída com sucesso!",
            didOpen: async () => {
                const formData = new FormData();
                Object.keys(data).forEach(k => {
                    formData.append(k, data[k]);
                })
                const res = await setMaintenanceWarning(formData);
                if(res) {
                    resetForm();
                    setShow(false);
                    handleClose();
                } else {
                    Swal.fire('OPS!', res.message, 'error');
                }
            }      
        });
    }

    const hide = () => {
        resetForm();
        handleClose();
    }

    const handleHShow = () => {
        setHShow(true);
    }

    const handleHClose = () => {      
        setHShow(false);
    }

    const resetForm = () => {
        const { maintenanceWarningText, maintenanceWarningContact } = getValues();
        
        if (maintenanceWarningText || maintenanceWarningContact) {
            sessionStorage
            .setItem("ncModalForm", JSON.stringify({ 
                maintenanceWarningText: maintenanceWarningText, 
                maintenanceWarningContact: maintenanceWarningContact
            }));
        }

        reset({
            maintenanceWarningText: '',
            maintenanceWarningContact: ''
        });
    }

    useEffect(()=>{
        const formValues = JSON.parse(sessionStorage.getItem("ncModalForm"));
        if (formValues) {
            setValue("maintenanceWarningText", formValues.maintenanceWarningText);
            setValue("maintenanceWarningContact", formValues.maintenanceWarningContact);
        }
    }, [systemMaintenance, setValue]);

    return (
        <>
            <Modal size="lg" show={show} onHide={hide} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Tratativas com o cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card subtitle">
                        <div className="card-body d-flex align-items-center justify-content-between">
                            <label className="text-muted">
                                <b>NC {systemMaintenance.system_maintenance_id}</b> - {systemMaintenance.system_maintenance_text}
                            </label>
                            <button type="button" className="btn btn-outline-secondary"  title="Infos NC" onClick={() => handleHShow()}><i className="bi bi-eye-fill"></i></button>
                        </div>
                    </div>
                    <br/>
                    <div className="card card_table border-0">
                        <div className="card-body">
                            <Table data={Array.isArray(warningList) ? warningList : []} columns={columns} btnColumn={5} actBtns={actionButtons} />
                        </div>
                    </div>
                    <hr/>
                    <form id="form" onSubmit={handleSubmit(save)} noValidate>
                        <div className='col-12 mb-3'>
                            <div className="form-floating">
                                <textarea className="form-control" id="descRport" placeholder="Descrição" aria-label='Descrição' {... register('maintenanceWarningText',{required:true})}></textarea>
                                <label htmlFor="descRport">Descrição</label>
                            </div>
                            {errors.maintenanceWarningText?.type === 'required' && <div className='text-danger'><small>Informe a descrição</small></div>}
                        </div>
                        <div className='col-12 mb-3'>
                            <div className="form-floating">
                                <input className="form-control" id="encarregado" placeholder="Nome do encarregado" aria-label='Nome do Encarregado' {... register('maintenanceWarningContact',{required:true})}></input>
                                <label htmlFor="encarregado">Nome do encarregado</label>
                            </div>
                            {errors.maintenanceWarningContact?.type === 'required' && <div className='text-danger'><small>Informe o nome do encarregado</small></div>}
                        </div>
                        <div className='col-12'>
                            <button type="submit" className='btn btn-success float-end'>Adicionar</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <HistoryModal hShow={hShow} handleHClose={handleHClose} systemMaintenance={systemMaintenance}/>
        </>
    )
}