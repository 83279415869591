import React from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import Main from '../Main/Main';
import ListDaily from '../List/Daily';
import ListUrgents from '../List/Urgents';
import ListSupervision from '../List/Supervision';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';

export default function MainRoute(){
    return(
        <div className="wrapper">
            <BrowserRouter>
                <React.Suspense fallback={<></>}>
                    <Header base_url="/gestao" />          
                    <Sidebar base_url="/gestao" />
                    <Routes>
                        <Route element={<ListDaily />} path="/gestao" />
                        <Route element={<Main />} path={"/gestao/:clientId"} />
                        <Route element={<ListUrgents />} path="/list/urgents" />
                        <Route element={<ListSupervision />} path="/list/supervision" />
                    </Routes>
                </React.Suspense>
            </BrowserRouter>
        </div>
    )
}