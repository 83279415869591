import React, { useState, useEffect }from 'react';
import Table from '../Table/Table';
import NCModal from '../Main/NCModal';

import { getGestaoIssuesList, getWarningList } from '../Api/Api';

import IssuesClosureModal from '../Custom/IssuesClosureModal';

export default function Urgents(){

    const [ criticals, setCriticals ] = useState([]);
    const [ systemMaintenance, setSystemMaintenance ] = useState([]);
    const [ warningList, setWarningList ] = useState([]);
    const [ show, setShow ] = useState(false);
    const [showClosureModal, setShowClosureModal] = useState(false);

    const intervalMinutes = 1;

    const columns = [
        {
            title: 'NC',
            data: 'system_maintenance_id',
            className: 'text-center align-middle',
        },
        {
            title: 'Unidade',
            data: 'info_name',
            className: 'text-center align-middle',
        },
        {
            title: 'Sistema',
            data: 'system_type_name',
            className: 'text-center align-middle',
        },
        {
            title: 'Problema',
            data: 'system_maintenance_text',
            className: 'text-center align-middle',
        },
        {
            title: 'Data de criação',
            data: 'system_maintenance_created',
            className: 'text-center align-middle',
            render: (data, x, row) => {
                return `${data}`
            }
        },
        {
            title: 'Prazo sugerido',
            data: 'system_maintenance_expiration',
            className: 'text-center align-middle',
            render: (data, x, row) => {
                return `${data}`
            }
        },
        {
            title: 'Prazo documental',
            data: 'document_expiration',
            className: 'text-center align-middle',
            render: function (data) {
                if (data) {
                    return data;
                }else{
                    return "-";
                }
            }
        },
        {
            title: 'Ações',
            data: null,
            className: 'text-center align-middle',
        }
    ];

    const actionButtons = (row) => {
        return (
          <>
            <button className="btn btn-sm btn-primary m-2" onClick={()=>handleShow(row)}>Tratativas</button>
            <button type="btn" className='btn btn-sm btn-danger pl-1 pr-1' onClick={()=>handleEnd(row)} >Encerrar</button>
          </>
        )
    }

    const handleClosureModal = async () => {
        setShowClosureModal(true);
    }

    const handleCloseClosureModal = () => {
        getOrganizationClients();
        setShowClosureModal(false);
    }

    const handleEnd = async (row) => {
        await setSystemMaintenance(row);
        handleClosureModal();
    };

    const handleShow = async (row) => {
        setSystemMaintenance([]);
        setShow(true);
        setSystemMaintenance(row);
        const rst = await getWarningList(row.system_maintenance_id);
        if(rst){
            setWarningList(rst.data);
        }
    }

    const handleClose = () => {
        getOrganizationClients();
        setShow(false);
    }

    const getOrganizationClients = async () => {
        var data = {};
        const res = await getGestaoIssuesList(data);
        if(res.data){
            setCriticals(res.data);
        }
    }

    useEffect(()=>{
        getOrganizationClients();

        const interval = setInterval(() => {
            if (!show) {
                getOrganizationClients();
            }
        }, 1000 * 60 * intervalMinutes);

        return () => clearInterval(interval);
    }, [show]);

    return(
        <>
            <section className="section">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            Urgentes/Críticos (diário)
                        </div>
                        <div className="card-body">
                            <Table data={criticals} columns={columns} btnColumn={7} actBtns={actionButtons} keepState={true}/>
                        </div>
                    </div>
                </div>
            </section>
            <IssuesClosureModal isOpen={showClosureModal} systemMaintenance={systemMaintenance} onClose={handleCloseClosureModal} />
            <NCModal show={show} handleClose={handleClose} userId={sessionStorage.getItem('userId')} systemMaintenance={systemMaintenance} warningList={warningList} setShow={setShow} />
        </>
    )
}